<template>
    <div class="interfaceConfig-table">
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleAdd">
                新增
            </el-button>
            <!-- <el-button type="success" size="small" @click="multiSave">
                批量保存
            </el-button> -->
            <el-button type="danger" size="small" @click="multiDel">
                批量删除
            </el-button>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
                @selection-change="handleSelectionChange"
            >
                >
                <el-table-column
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    label="接口id"
                >
                    <template slot-scope="scope">
                        {{ scope.row.interface_mode }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="接口名称"
                >
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项说明"
                >
                    <template slot-scope="scope">
                        {{ scope.row.remarks }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="显示顺序"
                >
                    <template slot-scope="scope">
                        {{ scope.row.in_entity_order }}
                    </template>
                </el-table-column>
                <el-table-column label="配置接口">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="configInterface(scope.$index, scope.row)"
                        >
                            配置接口
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="editInterface(scope.$index, scope.row)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        directoryId: {
            type: String,
        },
    },
    data() {
        return {
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: [], // 多选选中数据集合
        };
    },
    watch: {
        directoryId(val) {
            if (val) {
                this.directoryId = val;
                this.getEntityInfo();
            }
        },
    },
    computed: {},
    methods: {
        // 获取信息项
        getEntityInfo() {
            this.$axios
                .get('/interfaceApi/datacenter/interface/info/getpage/' + this.directoryId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 新增接口
        handleAdd() {
            this.$toast(
                {
                    title: true,
                    text: '新增接口',
                    type: 'eject',
                    width: '9rem',
                    height: '70%',
                    t_url: 'dataBaseCenter/dataCollection/interfaceConfig/addPop',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, directoryId: this.directoryId, closeCall: this.addFinshed },
                });
        },
        // 编辑接口
        editInterface(index, rowData) {
            this.$toast(
                {
                    title: true,
                    text: '修改接口',
                    type: 'eject',
                    width: '9rem',
                    height: '70%',
                    t_url: 'dataBaseCenter/dataCollection/interfaceConfig/addPop',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, rowData: rowData, directoryId: this.directoryId, closeCall: this.addFinshed },
                });
        },
        // 新增完成
        addFinshed() {
            window.hideToast();
            this.getEntityInfo();
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getEntityInfo();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getEntityInfo();
        },
        // 多选事件
        handleSelectionChange(val) {
            this.selectData = val;
        },
        // 批量删除
        multiDel() {
            if (this.selectData.length < 1) {
                this.$message.warning('请先勾选将要保存的数据');
                return;
            }
            let ids = '';
            this.selectData.map(item => {
                ids += item.id + ',';
            });
            ids = ids.substring(0, ids.length - 1);
            this.$axios
                .delete('/interfaceApi/datacenter/metadata/entity/info/' + ids)
                .then(res => {
                    if (res) {
                        this.$message.success('删除成功');
                        this.getEntityInfo();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 配置接口
        configInterface(index, row) {
            this.$toast(
                {
                    title: true,
                    text: '采集接口配置',
                    type: 'eject',
                    t_url: 'dataBaseCenter/dataCollection/interfaceConfig/configPop',
                    viewPosition: 'view',
                    extr: {
                        closeBtn: { width: '.4rem', height: '.4rem', 'line-height': '.4rem' },
                        directoryId: this.directoryId, rowData: row, closeCall: this.addFinshed },
                });

        },
    },
    created() {
        if (!this.directoryId) {
            // this.$message.warning('请先选择树节点');
            return;
        }
        this.getEntityInfo();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.interfaceConfig-table
    margin-left 2.4rem
    padding .15rem
    height 100%
    background #fff
    .handle-btn
        margin-bottom .1rem
        text-align right
    .table-con
        height calc(100% - 52px)
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
</style>